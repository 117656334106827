import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { ISideMenuItem } from 'src/interfaces/ISideMenu';

const MenuItems: ISideMenuItem[] = [
  {
    primaryText: 'Parceiras Exclusivas',
    route: '/parceiras',
    icon: LabelOutlinedIcon,
  },
  {
    primaryText: 'Benefícios Solicitados',
    icon: LabelOutlinedIcon,
    subItems: [
      {
        primaryText: 'Bolsas de Estudo',
        route: '/solicitacoes/bolsas-de-estudo',
        // icon: LabelOutlinedIcon,
      },
      {
        primaryText: 'Outros Benefícios',
        route: '/solicitacoes/outros-beneficios',
        // icon: LabelOutlinedIcon,
      },
    ],
  },
];

export default MenuItems;
