import { FC, useCallback, useState } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

import { IRouteInterface } from 'src/interfaces/IRoutes';

import PrivateLayout from './Layout';
import PrivateContext from './PrivateContext';

const Private: FC<IRouteInterface> = ({
  component: Component,
  title,
  ...rest
}) => {
  const user = useSelector((state) => state.auth.user);
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(true);
  const [layoutLoading, SetLayoutLoading] = useState(false);

  const openSideMenu = useCallback(() => {
    setSideMenuIsOpen(true);
  }, []);
  const closeSideMenu = useCallback(() => {
    setSideMenuIsOpen(false);
  }, []);

  const startLayoutLoading = useCallback(() => {
    SetLayoutLoading(true);
  }, []);
  const stopLayoutLoading = useCallback(() => {
    SetLayoutLoading(false);
  }, []);

  return (
    <Route
      {...rest}
      render={(props: RouteComponentProps) =>
        user ? (
          <>
            <Helmet>
              <title>{title}</title>
            </Helmet>
            <PrivateContext.Provider
              value={{
                sideMenuIsOpen,
                openSideMenu,
                closeSideMenu,
                layoutLoading,
                startLayoutLoading,
                stopLayoutLoading,
              }}
            >
              <PrivateLayout>
                <Component {...props} />
              </PrivateLayout>
            </PrivateContext.Provider>
          </>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
};

export default Private;
