import { FC, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { Tooltip, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { GridColDef, GridRowData } from '@material-ui/data-grid';
import BlockIcon from '@material-ui/icons/Block';
import CheckIcon from '@material-ui/icons/Check';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import EyeIcon from '@material-ui/icons/RemoveRedEye';
import ConfirmDialog from 'src/components/ConfirmDialog';
import { ConfirmDialogRef } from 'src/components/ConfirmDialog/interfaces';
import FilterDrawer from 'src/components/FilterDrawer';
import { FilterDrawerRef } from 'src/components/FilterDrawer/interfaces';
import Table from 'src/components/Table';
import TableActions from 'src/components/Table/Actions';
import CustomMenuItem from 'src/components/Table/CustomMenuItem';
import StatusIcon from 'src/components/Table/StatusIcon';
import { columnDateTime } from 'src/components/Table/types';
import usePagination from 'src/hooks/usePagination';
import { ICollaboratorScholarship } from 'src/interfaces/models/IPerson';
import api from 'src/services/api';
import { handleApiResponseErrors } from 'src/utils/errors';
import { formatDate } from 'src/utils/helpers';
import masks from 'src/utils/masks';
import CollaboratorScholarshipFilterSchema from 'src/validators/CollaboratorScholarship/filter.schema';

import Filters from './Filters';
import { Container } from './styles';

const List: FC = () => {
  const filterRef = useRef<FilterDrawerRef>(null);
  const approveDialogRef = useRef<ConfirmDialogRef>(null);
  const rejectDialogRef = useRef<ConfirmDialogRef>(null);
  const [
    selectedScholarship,
    setSelectedScholarship,
  ] = useState<ICollaboratorScholarship | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    handlePageChange,
    handlePageSizeChange,
    data,
    loading: paginationLoading,
    info,
    loadData,
  } = usePagination<ICollaboratorScholarship>(
    'beneficiary-admin/collaborator-scholarships',
    {
      order_by: [{ column: 'created_at', direction: 'desc' }],
    },
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID' },
    {
      field: 'person.name',
      headerName: 'Colaborador / Associado',
      flex: 1,
      valueGetter({ row }) {
        return row.person?.name;
      },
    },
    {
      field: 'name',
      headerName: 'Aluno',
      flex: 1,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.name}</Typography>
            <Typography variant="body2">{masks.cpf(row.document)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'unity.display_name',
      headerName: 'Unidade',
      minWidth: 110,
      // renderCell({ row }) {
      //   return (
      //     <div>
      //       <Typography variant="body2">{row.unity?.display_name}</Typography>
      //     </div>
      //   );
      // },
    },
    {
      field: 'partner.display_name',
      headerName: 'Parceira & Serviço',
      flex: 1,
      minWidth: 200,
      renderCell({ row }) {
        return (
          <div>
            <Typography variant="body2">{row.partner?.display_name}</Typography>
            <Typography variant="body2">
              {row.service?.name} ({row.teachForm?.name})
            </Typography>
          </div>
        );
      },
    },
    {
      field: 'created_at',
      headerName: 'Criado Em',
      ...columnDateTime,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      renderCell({ row }) {
        return (
          <>
            <StatusIcon status={row.status} />

            {row.confirmer && (
              <Tooltip
                title={`Confirmado por ${row.confirmer.name} em ${formatDate(
                  row.confirmed_at,
                  'dd/MM/yyyy HH:mm',
                )}`}
              >
                <span style={{ display: 'flex' }}>
                  <HowToRegIcon color="action" />
                </span>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      field: '',
      disableColumnMenu: true,
      sortable: false,
      align: 'center',
      renderCell({ row }) {
        if (row.status === 'rejected') return;

        return (
          <TableActions
            resource="Bolsa"
            resourceStatus={row.status}
            descriptionPrefix={`A bolsa do(a) ${row.name}`}
            onFinish={loadData}
            startItems={
              <>
                {row.status === 'pending' && (
                  <>
                    <CustomMenuItem
                      Icon={CheckIcon}
                      iconProps={{ color: 'primary' }}
                      text="Aprovar"
                      onClick={() => handleApproveDialog(row)}
                    />
                    <CustomMenuItem
                      Icon={BlockIcon}
                      iconProps={{ color: 'secondary' }}
                      text="Negar"
                      onClick={() => handleRejectDialog(row)}
                    />
                  </>
                )}
                {row.status === 'active' && (
                  <>
                    <CustomMenuItem
                      Icon={EyeIcon}
                      iconProps={{ color: 'primary' }}
                      text="Ver Voucher"
                      onClick={() => {
                        window.open(row.voucher_url, '_blank');
                      }}
                    />
                  </>
                )}
              </>
            }
          />
        );
      },
    },
  ];

  const handleApproveDialog = (gridRow: GridRowData) => {
    setSelectedScholarship(
      data.find((personSub) => personSub.id == gridRow.id) || null,
    );
    approveDialogRef.current?.show();
  };

  const handleApproveConfirm = async () => {
    try {
      setLoading(true);

      await api.post(
        `/beneficiary-admin/collaborator-scholarships/${selectedScholarship?.id}/approve`,
      );
      toast.success(`Bolsa aprovada!`);
      approveDialogRef.current?.hide();
      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao aprovar bolsa.');
    } finally {
      setLoading(false);
    }
  };

  const handleRejectDialog = (gridRow: GridRowData) => {
    setSelectedScholarship(
      data.find((personSub) => personSub.id == gridRow.id) || null,
    );
    rejectDialogRef.current?.show();
  };

  const handleRejectConfirm = async () => {
    try {
      setLoading(true);

      await api.post(
        `/beneficiary-admin/collaborator-scholarships/${selectedScholarship?.id}/reject`,
      );
      toast.success(`Bolsa negada!`);
      rejectDialogRef.current?.hide();
      loadData();
    } catch (error) {
      handleApiResponseErrors(error.response, 'Erro ao negar bolsa.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <FilterDrawer
        ref={filterRef}
        columns={columns.filter((column) => !column.hide && column.field)}
        yupSchema={CollaboratorScholarshipFilterSchema}
      >
        <Filters formRef={filterRef.current?.getFormRef()} />
      </FilterDrawer>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Typography variant="h5">Solicitações: Bolsas de Estudo</Typography>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Table
                autoHeight
                rows={data}
                page={info.current_page - 1}
                pageSize={info.per_page}
                rowCount={info.total}
                columns={columns}
                loading={paginationLoading}
                pagination
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                onFilterClick={filterRef.current?.open}
                canSearch
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <ConfirmDialog
        ref={approveDialogRef}
        title="Aprovar Bolsa?"
        description="Será enviado um email à pessoa com o voucher da bolsa em anexo e um email para a parceira para orçamento, caso a parceira esteja configurada como Notificar Parceiro."
        confirmColor="primary"
        onConfirm={handleApproveConfirm}
        loading={loading}
      />

      <ConfirmDialog
        ref={rejectDialogRef}
        title="Negar Bolsa?"
        description="Ao negar a bolsa será enviado um e-mail para a pessoa notificando a rejeição."
        confirmColor="secondary"
        onConfirm={handleRejectConfirm}
        loading={loading}
      />
    </Container>
  );
};

export default List;
