import yup from 'src/libs/yup';

const CollaboratorBonusFilterSchema = yup.object().shape({
  // person_id: yup.string().nullable().label('Pessoa'),
  // beneficiary_id: yup.string().nullable().label('Beneficiária'),
  // beneficiary_unity_id: yup.string().nullable().label('Unidade'),
  // partner_id: yup.string().nullable().label('Parceira'),
  // service_id: yup.string().nullable().label('Serviço'),
  person_name: yup.string().label('Nome Colaborador'),
  person_document: yup.string().label('CPF Colaborador'),
  partner_name: yup.string().label('Parceiro'),
  category_name: yup.string().label('Categoria'),
  name: yup.string().label('Nome'),
  document: yup.string().label('CPF'),
  status: yup.string().nullable().label('Status'),
});

export default CollaboratorBonusFilterSchema;
