import { FC, RefObject } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { FormHandles } from '@unform/core';
import Select from 'src/components/Form/Select';
import { Item } from 'src/components/Form/Select/interfaces';
import TextField from 'src/components/Form/TextField';

const statusItems: Item[] = [
  { key: 0, label: 'Pendente', value: 'pending' },
  { key: 1, label: 'Ativo', value: 'active' },
  { key: 2, label: 'Inativo', value: 'inactive' },
  { key: 3, label: 'Negado', value: 'rejected' },
];

interface FilterProps {
  formRef?: RefObject<FormHandles>;
}

const Filters: FC<FilterProps> = () => {
  return (
    <>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="person_name"
          label="Nome Colaborador / Associado"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="person_document"
          label="CPF Colaborador / Associado"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="partner_name" label="Nome do Parceiro" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="category_name"
          label="Categoria do Parceiro"
          size="small"
        />
      </Grid>

      <Grid item xs={12}>
        <TextField name="name" label="Nome Beneficiário" size="small" />
      </Grid>

      <Grid item xs={12}>
        <TextField name="document" label="CPF Beneficiário" size="small" />
      </Grid>

      <Grid item xs={6}>
        <Select name="status" label="Status" items={statusItems} size="small" />
      </Grid>
    </>
  );
};

export default Filters;
